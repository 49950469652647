import { RequestMainService } from "../request";

export class AgentService {
  static async getSytemReportAgent(params: any = {}) {
    return RequestMainService.get(`/admin/dig/agent-report-daily`, params).then(({ result }) => ({
      count: result.total,
      data: result.data,
    }));
  }
}
